
import axios from 'axios';


axios.defaults.baseURL = 'http://139.59.79.37/api'; 


const yourTokenHere = '66fc221ee24d0a9398043a24|YdyKGbfwPOTgE51XsaJI3r9kk5bTpdwhIsZDxoQF2a7cc9f3';
axios.defaults.headers.common['Authorization'] = `Bearer ${yourTokenHere}`;

export default axios;
